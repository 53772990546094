import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './styles/app.css';
import Start from './components/Start';
import Signup from './components/Signup';
import UserAgreement from './components/UserAgreement';
import Login from './components/Login';
import Home from './components/hero';
import Disclaimer from './components/disclaimer';
import Main from './components/main';
import GetPrediction from './components/getPrediction';
import { UserAuthContextProvider } from './components/UserAuthContext';
import PrivateRoute from './components/PrivateRoute';
import { LanguageProvider } from './components/LanguageContext'; // Import the LanguageProvider
import LanguageSwitcher from './components/LanguageSwitcher'; // Import LanguageSwitcher
import SendEmail from './components/sendEmail';

function App() {
  return (
    <UserAuthContextProvider>
      <LanguageProvider>
        <Router>
          <LanguageSwitcher /> {/* Add LanguageSwitcher here */}
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path='/' element={<Start />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/userAgreement" element={<UserAgreement />} />
              <Route path="/login" element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route path='/start' element={<Home />} />
                <Route path='/disclaimer' element={<Disclaimer />} />
                <Route path="/main" element={<Main />} />
                <Route path='/get-prediction' element={<GetPrediction />} />
                <Route path="/email" element={<SendEmail />} />
              </Route>
            </Routes>
          </Suspense>
        </Router>
      </LanguageProvider>
    </UserAuthContextProvider>
  );
}

export default App;
