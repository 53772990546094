import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from './UserAuthContext';
import logo from '../images/aviator1-removebg-preview.png';
import { useTranslation } from 'react-i18next'; // Import translation hook

const Login = () => {
  const { t } = useTranslation(); // Translation function
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, googleSignIn } = useUserAuth();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate('/start');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate('/');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="auth-form-container">
      <img src={logo} alt="aviator" />
      <h2>{t('login.title')}</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label htmlFor="email">{t('login.enterEmail')}</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder={t('login.emailPlaceholder')} // Updated placeholder to use translation key
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">{t('login.enterPassword')}</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder={t('login.passwordPlaceholder')} // Updated placeholder to use translation key
          />
        </div>
        <p id="forgot">
          <a href="https://t.me/+Ok7AfsOxaao1NDko">{t('login.forgotPassword')}</a>
        </p>
        <button className="btn" id="login" type="submit">{t('login.signInButton')}</button> {/* Updated sign-in button text */}
        <button className="btn" id="google" type="button" onClick={handleGoogleSignIn}>
          {t('login.googleSignInButton')} {/* Updated Google sign-in button text */}
        </button> 
      </form>
      <p id="create">
        {t('login.createAccountPrompt')} <a href="/signup">{t('login.createAccountLink')}</a>
      </p>
      <p id="telegram">
        {t('login.joinTelegram')} <a href="https://t.me/+FvRokI_u_ZAwMzQ0">{t('login.telegram')}</a> {/* Updated Telegram link text */}
      </p>
    </div>
  );
};

export default Login;
