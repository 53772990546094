// src/components/Main.js
import React from 'react';
import { useUserAuth } from './UserAuthContext';
import { Link } from 'react-router-dom';
import betwayy from '../images/bet.jpg';
import betking from '../images/02-mwos-main-logo.webp';
import xbet from '../images/1xbet.png';
import sporty from '../images/aviator1.jpg';
import aviator from '../images/gobanner.jpg';
import betway from '../images/betwinner.png';
import pesa from '../images/melbet.png';
import melbet from '../images/msport.jpeg';
import kingbet from '../images/pin up.png';
import betika from '../images/1win.jpeg';
import rotor from '../images/propeller-removebg-preview.png';
import { useTranslation } from 'react-i18next';

const Main = () => {
  const { logOut } = useUserAuth();
  const { t } = useTranslation();

  const handleLogout = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error.message);
    }
  };
	
  return (
    <div className='main'>
      <nav>
        <div className="navv">
          <h1>{t('main.welcome')}</h1>
          <button className='logout' onClick={handleLogout}>{t('main.logout')}</button>
        </div>
        <p>{t('main.chooseBettingSite')}</p>
      </nav>
      
      <div className="main-container">
        <p>{t('main.version')}</p>
        <ul>
          <li><Link to="/get-prediction"><img src={betking} alt="betting sites" /></Link></li>
          <li><a href='https://www.thegoatfixedtipster.com'><img src={aviator} alt="betting sites" /></a></li>
          <li><Link to="/get-prediction"><img src={betwayy} alt="betting sites" /></Link></li>
          <li><Link to="/get-prediction"><img src={betika} alt="betting sites" /></Link></li>
          <li><Link to="/get-prediction"><img src={sporty} alt="betting sites" /></Link></li>
          <li><Link to="/get-prediction"><img src={xbet} alt="betting sites" /></Link></li>
          <li><Link to="/get-prediction"><img src={pesa} alt="betting sites" /></Link></li>
          <li><Link to="/get-prediction"><img src={melbet} alt="betting sites" /></Link></li>
          <li><Link to="/get-prediction"><img src={kingbet} alt="betting sites" /></Link></li>
          <li><Link to="/get-prediction"><img src={betway} alt="betting sites" /></Link></li>
        </ul>
        <img src={rotor} alt="rotor" className="rotor" />
      </div>
      <ul className='link'>
        <li><Link to="/another-page">{t('main.link.goToAnotherPage')}</Link></li>
        {/* Add more links as needed */}
      </ul>
    </div>
  );
};

export default Main;
