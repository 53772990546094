import React, { useState, useEffect, useCallback } from 'react';
import logo from '../images/aviator1-removebg-preview.png';
import { useNavigate } from 'react-router-dom';
import rotor from '../images/propeller-removebg-preview.png';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';

const GetPrediction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isActivated, setIsActivated] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [activationCode, setActivationCode] = useState('');
  const [prediction, setPrediction] = useState('1.00x');
  const [lastRequestTime, setLastRequestTime] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [waitMessage, setWaitMessage] = useState('');
  const [showSignalLoading, setShowSignalLoading] = useState(false);
  const [validActivationCode, setValidActivationCode] = useState('');
  const [activationError, setActivationError] = useState('');

  // Determine if running in mobile app
  const isNativePlatform = Capacitor.isNativePlatform();
  
  // Use the appropriate base URL depending on environment
  const baseURL = 'https://aviator-l4i7.onrender.com';

  const fetchActivationCode = useCallback(async () => {
    try {
      const config = {
        headers: {
          'Cache-Control': 'no-cache',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        withCredentials: true
      };

      if (isNativePlatform) {
        // Additional headers for mobile requests
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
      }

      const response = await axios.get(`${baseURL}/api/activation-code`, config);
      
      if (response.data && response.data.activationCode) {
        setValidActivationCode(response.data.activationCode);
        setActivationError('');
      } else {
        throw new Error('Invalid response format');
      }
    } catch (error) {
      console.error('Error fetching activation code:', error);
      setActivationError(t('getPrediction.errorFetchingCode'));
      
      // Log detailed error information
      if (error.response) {
        console.log('Error response:', error.response.data);
        console.log('Error status:', error.response.status);
        console.log('Error headers:', error.response.headers);
      } else if (error.request) {
        console.log('Error request:', error.request);
      } else {
        console.log('Error message:', error.message);
      }
    }
  }, [isNativePlatform, t]); // Include dependencies for useCallback

  useEffect(() => {
    // Check for stored activation state
    const storedActivation = localStorage.getItem('isActivated');
    if (storedActivation === 'true') {
      setIsActivated(true);
    }

    fetchActivationCode();
    
    // Retry logic for mobile platforms
    if (isNativePlatform) {
      const retryTimeout = setTimeout(() => {
        if (!validActivationCode) {
          fetchActivationCode();
        }
      }, 2000); // Retry after 2 seconds if initial fetch fails
      
      return () => clearTimeout(retryTimeout);
    }
  }, [isNativePlatform, fetchActivationCode, validActivationCode]);

  // Rest of the component remains the same...
  
  useEffect(() => {
    let interval;
    if (lastRequestTime) {
      interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - lastRequestTime;
        if (timeDifference >= 10 * 1000) {
          setIsButtonDisabled(false);
          setWaitMessage('');
          setLastRequestTime(null);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [lastRequestTime]);

  const handleGetPrediction = () => {
    const currentTime = new Date().getTime();
    if (!isActivated) {
      setShowPopup(true);
      return;
    }

    if (!lastRequestTime || (currentTime - lastRequestTime) >= 10 * 1000) {
      const newPrediction = (Math.random() * (6 - 2) + 2).toFixed(2);
      let currentPrediction = 1.00;
      setPrediction(currentPrediction.toFixed(2) + 'x');

      const predictionInterval = setInterval(() => {
        currentPrediction += 0.10;
        setPrediction(currentPrediction.toFixed(2) + 'x');

        if (currentPrediction >= parseFloat(newPrediction) - 0.30 && !showSignalLoading) {
          setShowSignalLoading(true);
          setTimeout(() => {
            setShowSignalLoading(false);
            navigate('/email');
          }, 3000);
        }

        if (currentPrediction >= parseFloat(newPrediction)) {
          clearInterval(predictionInterval);
          setPrediction(newPrediction + 'x');
        }
      }, 30);

      setLastRequestTime(currentTime);
      setIsButtonDisabled(true);
    } else {
      setWaitMessage(t('getPrediction.waitMessage'));
      setTimeout(() => {
        setWaitMessage('');
      }, 3000);
    }
  };

  const handleActivate = () => {
    setActivationError('');
    if (!activationCode.trim()) {
      setActivationError(t('getPrediction.emptyActivationCode'));
      return;
    }

    // Trim and compare activation codes
    if (activationCode.trim() === validActivationCode?.trim()) {
      setIsActivated(true);
      setShowPopup(false);
      setActivationCode('');
      // Store activation state
      try {
        localStorage.setItem('isActivated', 'true');
      } catch (error) {
        console.error('Error storing activation state:', error);
      }
    } else {
      setActivationError(t('getPrediction.invalidActivationCode'));
    }
  };

  return (
    <div className="get-prediction-container">
      {(showPopup || showSignalLoading) && <div className="overlay"></div>}
      <div className={`predictor ${showPopup || showSignalLoading ? 'blur' : ''}`}>
        <img src={logo} alt="Aviator" />
        <p>{t('getPrediction.aviatorPredictor')} {t('getPrediction.version')}</p>
        <h1>{prediction}</h1>
        <button className='btn' onClick={handleGetPrediction} disabled={isButtonDisabled}>
          {t('getPrediction.getPrediction')}
        </button>
        {waitMessage && <p className="message">{waitMessage}</p>}
      </div>

      {showPopup && (
        <div className="popup">
          <h2>{t('getPrediction.activationRequired')}</h2>
          <p>{t('getPrediction.activationInfo')}</p>
          <input
            type="text"
            value={activationCode}
            onChange={(e) => setActivationCode(e.target.value)}
            placeholder={t('getPrediction.enterActivationCode')}
          />
          {activationError && <p className="error-message">{activationError}</p>}
          <button onClick={handleActivate}>{t('getPrediction.activate')}</button>
        </div>
      )}

      {showSignalLoading && (
        <div className="popup signal">
          <h2>{t('getPrediction.signalLoading')}</h2>
          <div className="loader">
            <img src={rotor} alt="rotor" className='rotor' />
            <p>{t('getPrediction.pleaseWait')}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetPrediction;