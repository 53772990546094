// src/components/Signup.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from './UserAuthContext';
import { useTranslation } from 'react-i18next'; // i18next for translations

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const { signUp } = useUserAuth();
  const { t } = useTranslation(); // hook to use translations
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      setError(t('signup.passwordsDoNotMatch')); // use translated text for error
      return;
    }

    try {
      await signUp(email, password);
      alert(t('signup.verificationEmail')); // use translated text for alert
      navigate('/userAgreement');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="signup-container">
      <h2>{t('signup.title')}</h2> {/* Translated title */}
      <p id="login">{t('signup.loginPrompt')} <a href="/login">{t('signup.loginHere')}</a></p>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="email"
            placeholder={t('signup.emailPlaceholder')} // Translated placeholder
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder={t('signup.passwordPlaceholder')} // Translated placeholder
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            placeholder={t('signup.confirmPasswordPlaceholder')} // Translated placeholder
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button className="btn" type="submit">{t('signup.signUpButton')}</button> {/* Translated button */}
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default Signup;
