import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
  onAuthStateChanged,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBlcKVh3cntxFD48nioF5q0erbtcsNG3C8",
  authDomain: "avipred-a0a51.firebaseapp.com",
  projectId: "avipred-a0a51",
  storageBucket: "avipred-a0a51.appspot.com",
  messagingSenderId: "764082730451",
  appId: "1:764082730451:web:a43e732d8c9f28d0d4ab26",
  measurementId: "G-XTYQ874KP2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export {
  auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
  onAuthStateChanged,
};

