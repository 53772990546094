// src/components/UserAgreement.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // i18next for translations

const UserAgreement = () => {
  const [timeLeft, setTimeLeft] = useState(10);
  const [buttonEnabled, setButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation(); // Translation hook

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft(prevTime => (prevTime - 1));
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setButtonEnabled(true);
    }
  }, [timeLeft]);

  const handleAgree = () => {
    navigate('/login');
  };

  return (
    <div className="cont-debug">
      <div style={styles.container} className='disc-cont'>
        <h3>{t('userAgreement.title')}</h3> {/* Translated title */}
        <p>{t('userAgreement.message1')}</p> {/* Translated message */}
        <p>{t('userAgreement.message2')}</p> {/* Translated message */}
        
        <ul>
          <li><strong>-Telegram:</strong><a href="https://t.me/+FvRokI_u_ZAwMzQ0">{t('userAgreement.telegram')}</a></li>
          <li><strong>-WhatsApp:</strong><a href="wa.me:+254106373357">{t('userAgreement.whatsapp')}</a></li>
        </ul>

        <div className="count">
          <button 
            onClick={handleAgree} 
            disabled={!buttonEnabled} 
            style={buttonEnabled ? styles.buttonEnabled : styles.buttonDisabled}
          >
            {t('userAgreement.continue')}
          </button>
          <p className='btnn'>{timeLeft > 0 ? `${t('userAgreement.wait')} ${timeLeft}` : t('userAgreement.ready')}</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  buttonEnabled: {
    backgroundColor: 'red',
    color: 'black',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    fontWeight: '700',
    borderRadius: '7px',
  },
  buttonDisabled: {
    backgroundColor: 'gray',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    fontWeight: '700',
    borderRadius: '7px',
  }
};

export default UserAgreement;
