import React, { createContext, useState } from 'react';
import i18n from 'i18next'; // Ensure you import i18n

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('en');

  const setLanguage = (language) => {
    setCurrentLanguage(language);
    i18n.changeLanguage(language); // Change language in i18n
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
