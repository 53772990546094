import React, { useContext } from 'react';
import { LanguageContext } from './LanguageContext'; // Import your Language Context

const LanguageSwitcher = () => {
  const { currentLanguage, setLanguage } = useContext(LanguageContext);

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'ar', name: 'Arabic' },
    { code: 'de', name: 'German'},
    { code: 'pt', name: 'Portuguese'},
    { code: 'hi', name: 'Hindi'},
    { code: 'zh', name: 'Chinese'},
    { code: 'fr', name: 'French'},
  ];

  const handleChangeLanguage = (language) => {
    setLanguage(language);
  };

  return (
    <div className="language-switcher">
      <span>{languages.find(lang => lang.code === currentLanguage).name}</span>
      <select onChange={(e) => handleChangeLanguage(e.target.value)} value={currentLanguage}>
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSwitcher;
