import React, { useState } from 'react';
import { useUserAuth } from './UserAuthContext'; 
import { IonIcon } from '@ionic/react';
import { star } from 'ionicons/icons';
import rotor from '../images/propeller-removebg-preview.png';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const IssueResolvePage = () => {
    const { user } = useUserAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSendEmail = async () => {
        if (!user?.email) {
            toast.error('Please log in to report an issue');
            return;
        }

        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);
        // toast.info('Submitting, wait...'); // Show loading toast


        try {
            const emailData = {
                to: user.email,
                to_name: user.displayName || 'Client',
                subject: 'Issue Resolution Request',
            };

            console.log('Sending request with data:', emailData); // Debug log

            // Add headers to the request
            

            const response = await axios.post(
                'https://aviator-l4i7.onrender.com/send-email',
                emailData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

            console.log('Response:', response.data); // Debug log

            if (response.data && (response.data.success || response.data === 'Email sent successfully')) {
                toast.success('Issue reported successfully!');
            } else {
                throw new Error('Unexpected server response');
            }
        } catch (error) {
            console.error('Request error:', error);
            console.error('Error response:', error.response?.data);
            
            let errorMessage = 'Failed to send request. Please try again later.';
            
            // Handle different types of errors
            if (error.response) {
                // Server responded with error
                errorMessage = error.response.data?.message || errorMessage;
            } else if (error.request) {
                // Request was made but no response
                errorMessage = 'No response from server. Please check your connection.';
            } else {
                // Request setup error
                errorMessage = error.message || errorMessage;
            }

            toast.error(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="error-page">
            <ul className="error">
                <li><IonIcon icon={star} /></li>
                <li><IonIcon icon={star} /></li>
                <li><IonIcon icon={star} /></li>
                <li><IonIcon icon={star} /></li>
                <li><IonIcon icon={star} /></li>
            </ul>
            <h2>Server Error</h2>
            <p>Contact <a href="https://t.me/+FvRokI_u_ZAwMzQ0">Admin</a> for server VPN.</p>
            <button 
                onClick={handleSendEmail} 
                disabled={isSubmitting}
                style={{ opacity: isSubmitting ? 0.7 : 1 }}
            >
                {isSubmitting ? 'Submitting...' : 'Resolve Issue'}
            </button>
            <ToastContainer 
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ul className="error">
                <li><IonIcon icon={star} /></li>
                <li><IonIcon icon={star} /></li>
                <li><IonIcon icon={star} /></li>
                <li><IonIcon icon={star} /></li>
                <li><IonIcon icon={star} /></li>
            </ul>
            <div className="rotor-cont">
                <img src={rotor} alt="rotor" className="rotor" />
            </div>
        </div>
    );
};

export default IssueResolvePage;