// src/components/Home.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import rotor from '../images/propeller-removebg-preview.png';
import { useTranslation } from 'react-i18next';

const Disclaimer = () => {
	const { t } = useTranslation(); // Use the translation hook
	const navigate = useNavigate();

	useEffect(() => {
		const timer = setTimeout(() => {
			navigate('/main');
		}, 6000);

		return () => clearTimeout(timer);
	}, [navigate]);

	const style = {
		fontSize: '2rem',
		fontWeight: 'bold',
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem'
	};
	const year = new Date().getFullYear();

	return (
		<div  className='disclaimer'>
			<div style={style} className="top-cont">
				<p >{t('disclaimer.title')}</p> <br/>
				<p>{t('disclaimer.version')}</p>
			</div>

			<div className="bottom-cont">
				<img className='rotor' src={rotor} alt="spin" />
				<ul>
					<li>{t('disclaimer.official')}</li>
					<li>{t('disclaimer.note')}</li>
					<li>{t('disclaimer.points.0')}</li>
					<li>{t('disclaimer.points.1')}</li>
				</ul>
				<p>&copy;{year}</p>
			</div>
		</div>
	);
};

export default Disclaimer;
