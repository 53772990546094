import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rotor from '../images/propeller-removebg-preview.png'


const Start = () => {
    const navigate = useNavigate();

    useEffect(() => {
		const timer = setTimeout (() => {
			navigate('/login');
		}, 4000);

		return () => clearTimeout(timer);
	}, [navigate]);
    return ( 
        <div className="start-cont">
            <img src={rotor} alt="rotor" className="rotor"/>
        </div>
     );
}
 
export default Start;
