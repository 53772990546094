// src/components/Home.js
import React, { useEffect } from 'react';
import rotor from '../images/propeller-removebg-preview.png';
import { useNavigate } from 'react-router-dom';



const Home = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const timer = setTimeout(() => {
			navigate('/disclaimer');
		}, 6000); 

		return () => clearTimeout(timer);
	}, [navigate]);
  
	const year = new Date().getFullYear();



  return (
    <div className='hero-cont'>
      <h2>predictor aviator</h2> <br/>
      <span id='version'>v12.0.5</span>
      <img className='rotor' src={rotor} alt="spin" />
      <p id='copy'>&copy;{year}</p>
    </div>
  );
};

export default Home;

